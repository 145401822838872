import React from "react";
import styles from "./Home.module.css";
import Banner from "../../assets/banner.png";
import PoweredBy from "../PoweredBy";

const Home = () => {
  return (
    <div style={{ height: "80vh" }} className={styles.pageContainer}>
      <section className={styles.hero}>
        <h1 style={{ marginBottom: "100px" }}>
          Easiest invoicing for stores and small businesses
        </h1>

        <div className={styles.imgContainer}>
          <img
            style={{
              width: "100%",
              maxWidth: "800px",
              height: "auto",
              objectFit: "contain",
              borderRadius: "20px",
              border: "1px solid white",
            }}
            src={Banner}
            alt="invoicing-app"
          />
        </div>
      </section>
      <PoweredBy />
    </div>
  );
};

export default Home;
