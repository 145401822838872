import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Person, Key, VisibilityOff, Visibility } from "@mui/icons-material";
import { useSnackbar } from "react-simple-snackbar";
import { updatePassword } from "../../api";

export default function ResetPassword() {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    showOld: false,
    showNew: false,
    showConfirm: false,
  });

  const [updating, setUpdating] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const handleChange = (e) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleToggleVisibility = (e, name) => {
    e.preventDefault();
    if (name === "oldPassword") {
      setFormData((prev) => ({ ...prev, showOld: !prev.showOld }));
    } else if (name === "newPassword") {
      setFormData((prev) => ({ ...prev, showNew: !prev.showNew }));
    } else {
      setFormData((prev) => ({ ...prev, showConfirm: !prev.showConfirm }));
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    let payload = {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
      confirmPassword: formData.confirmPassword,
    };

    setUpdating(true);
    try {
      const { data } = await updatePassword(formData);
      setFormData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        showOld: false,
        showNew: false,
        showConfirm: false,
      });
      openSnackbar("Password updated successfully!");
    } catch (error) {
      console.log(error);
    }

    setUpdating(false);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        paddingTop: "60px",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmitForm}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
          width: "100%",
          maxWidth: "450px",
          height: "100%",
        }}
      >
        <TextField
          disabled={updating}
          onChange={handleChange}
          required
          label="Old Password"
          variant="outlined"
          name="oldPassword"
          value={formData.oldPassword}
          fullWidth
          type={formData.showOld ? "text" : "password"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={updating}
                  onClick={(e) => handleToggleVisibility(e, "oldPassword")}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {" "}
                  {formData.showOld ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          disabled={updating}
          onChange={handleChange}
          required
          label="New Password"
          variant="outlined"
          name="newPassword"
          value={formData.newPassword}
          fullWidth
          type={formData.showNew ? "text" : "password"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={updating}
                  onClick={(e) => handleToggleVisibility(e, "newPassword")}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {" "}
                  {formData.showNew ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          disabled={updating}
          onChange={handleChange}
          required
          label="Confirm Password"
          variant="outlined"
          name="confirmPassword"
          value={formData.confirmPassword}
          fullWidth
          type={formData.showConfirm ? "text" : "password"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={updating}
                  onClick={(e) => handleToggleVisibility(e, "confirmPassword")}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {" "}
                  {formData.showConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          disabled={updating}
          variant="contained"
          disableElevation
          type="submit"
          style={{ marginTop: "20px" }}
        >
          {updating ? "Updating password..." : "Reset Password"}
        </Button>
      </Box>
    </div>
  );
}
