import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import styles from "./Header.module.css";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../../assets/logowithname.png";
import { Menu } from "@material-ui/core";
// import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const history = useHistory();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const logout = () => {
    handleClose();
    dispatch({ type: "LOGOUT" });
    history.push("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;
    // setUser(JSON.parse(localStorage.getItem('profile')))
    //If token expires, logout the user
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    // eslint-disable-next-line
  }, [location, user]); //when location changes, set the user

  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const openLink = (link) => {
    handleClose();
    history.push(`/${link}`);

    // setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      // setOpen(false);
    }
  }

  if (!user)
    return (
      <div style={{ backgroundColor: "black" }} className={styles.header2}>
        <img
          style={{ width: "150px", cursor: "pointer" }}
          onClick={() => history.push("/")}
          src={Logo}
          alt="webspruce-logo"
        />
        <button onClick={() => history.push("/login")} className={styles.login}>
          Get started
        </button>
      </div>
    );
  return (
    <div
      style={{ backgroundColor: "black", paddingRight: "30px" }}
      className={styles.header}
    >
      <div className={classes.root}>
        <div>
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Avatar style={{ backgroundColor: "#1976D2" }}>
              {user?.result?.name?.charAt(0)}
            </Avatar>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => openLink("settings")}>
              {(user?.result?.name).split(" ")[0]}
            </MenuItem>
            {user?.result?.oAuthLogged ? null : (
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  history.push("/reset-password");
                }}
              >
                Reset Password
              </MenuItem>
            )}
            <MenuItem onClick={() => logout()}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
