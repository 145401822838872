import React, { useContext } from "react";
import {
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
// icons
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { deleteItem, getItemList } from "../../api";
import AddItem from "./AddItem";
import { useSnackbar } from "react-simple-snackbar";
import DeleteModal from "../DeleteModal/DeleteModal";
import UpdateCountContext from "../../context/UpdateCountContext";

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const headerStyle = { borderBottom: "none", textAlign: "center" };

const tableStyle = {
  width: 160,
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};

const rows = [
  {
    invoiceNumber: "3",
    itemName: "Pencile",
    stock: "200",
    description: "lorem ipsume doll",
    price: "20",
  },
];

export default function Item() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [items, setItems] = React.useState([]);
  const [itemEditModal, setItemEditModal] = React.useState({
    open: false,
    data: null,
  });
  const { count } = useContext(UpdateCountContext);

  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);

  const getItems = async () => {
    try {
      const { data } = await getItemList();
      setItems(data.data);
      console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      const { data } = await deleteItem(itemId);
      getItems();
      openSnackbar("Item deleted successfully!");
    } catch (error) {}
  };

  React.useEffect(() => {
    getItems();
  }, [count]);

  const handleCloseItemModal = () =>
    setItemEditModal({ open: false, data: null });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Container
        style={{
          width: "85%",
          paddingTop: "70px",
          paddingBottom: "50px",
          border: "none",
        }}
      >
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell style={headerStyle}>#SN</TableCell>
                <TableCell style={headerStyle}>Item Name</TableCell>
                <TableCell style={headerStyle}>Description</TableCell>
                <TableCell style={headerStyle}>Quantity in stock</TableCell>
                <TableCell style={headerStyle}>Price (INR)</TableCell>
                <TableCell style={headerStyle}>Edit</TableCell>
                <TableCell style={headerStyle}>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? items.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : items
              ).map((row, index) => (
                <TableRow key={row._id} style={{ cursor: "pointer" }}>
                  <TableCell style={tableStyle}>{index + 1}</TableCell>
                  <TableCell style={tableStyle}>
                    {" "}
                    {capitalizeFirstLetter(row.name)}{" "}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {" "}
                    {row.description || "NA"}{" "}
                  </TableCell>
                  <TableCell style={tableStyle}> {row.stock} </TableCell>
                  <TableCell style={tableStyle}> {row.price}</TableCell>
                  <TableCell style={tableStyle}>
                    {" "}
                    <IconButton
                      onClick={() =>
                        setItemEditModal({ open: true, data: row })
                      }
                      color="primary"
                    >
                      <BorderColorIcon fontSize="small" />
                    </IconButton>
                  </TableCell>

                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    <IconButton
                      onClick={() => setModalOpen(true)}
                      color="error"
                    >
                      <DeleteOutlineRoundedIcon
                        color="error"
                        fontSize="small"
                      />
                    </IconButton>
                  </TableCell>
                  <DeleteModal
                    open={modalOpen}
                    text="item"
                    id={row._id}
                    onDeleteClick={handleDeleteItem}
                    setModalOpen={setModalOpen}
                  />
                </TableRow>
              ))}
            </TableBody>

            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={7}
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </Table>
        </TableContainer>
      </Container>
      {itemEditModal.open && (
        <AddItem
          open={itemEditModal.open}
          onItemUpdate={getItems}
          itemToEdit={itemEditModal.data}
          onClose={handleCloseItemModal}
        />
      )}
    </div>
  );
}
