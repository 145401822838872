import React, { useState, useEffect, useContext } from "react";
import styles from "./Invoice.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toCommas } from "../../utils/utils";
import { Badge, Box } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import {
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { initialState } from "../../initialState";
import currencies from "../../currencies.json";
import {
  createInvoice,
  getInvoice,
  updateInvoice,
} from "../../actions/invoiceActions";
import { getClientsByUser } from "../../actions/clientActions";
import AddClient from "./AddClient";
import InvoiceType from "./InvoiceType";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getItemList } from "../../api";
import UpdateCountContext from "../../context/UpdateCountContext";
import AddItem from "../Items/AddItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const Invoice = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState(currencies[0].value);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(
    today.getTime() + 7 * 24 * 60 * 60 * 1000
  );
  const [client, setClient] = useState(null);
  const [type, setType] = useState("Invoice");
  const [status, setStatus] = useState("");
  const { count } = useContext(UpdateCountContext);
  const { id } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  const { invoice } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = React.useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("mousewheel", function (event) {
        this.blur();
      });
    }

    return;
  }, []);

  const [allItems, setAllItems] = useState([]);
  const [taxRates, setTaxRates] = useState([]);

  const useStyles = makeStyles({
    select: {
      "&:before": {
        borderColor: "red",
      },
      "&:after": {
        borderColor: "white",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "white",
      },
    },
    icon: {
      fill: "white",
    },
    root: {
      color: "white",
    },
  });

  const getItems = async () => {
    try {
      const { data } = await getItemList();
      setAllItems(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getItems();
  }, [count]);

  useEffect(() => {
    if (user && user.result && user.result._id) {
      getTotalCount();
    }
    // eslint-disable-next-line
  }, [location, user?.result?._id]);

  const getTotalCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/invoices/count?searchQuery=${user?.result?._id}`
      );

      setInvoiceData({
        ...invoiceData,
        invoiceNumber: (Number(response.data) + 1).toString().padStart(3, "0"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getInvoice(id));
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch(
      getClientsByUser({ search: user?.result._id || user?.result?.googleId })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      // setInvoiceData(invoice);

      setClient(invoice.client);
      setType(invoice.status === "Paid" ? "Receipt" : invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      let data = {
        ...invoice,
        items: [
          ...invoice.items.map((i) => ({
            ...i,
            itemName: i.itemId,
            unitPrice: i.price,
          })),
        ],
      };
      setInvoiceData(data);

      let items = data.items;
      let taxRates = [];
      for (let item of items) {
        let newTaxRate = {};
        if (item.gst) {
          newTaxRate["rate"] = item.gst;
          newTaxRate["amountCollected"] =
            (item.unitPrice *
              item.quantity *
              (1 - item.discount / 100) *
              item.gst) /
            100;
        }
        newTaxRate["amount"] =
          item.unitPrice *
          item.quantity *
          (1 - item.discount / 100) *
          (1 + item.gst / 100);
        taxRates.push(newTaxRate);
      }

      let totalAmount = taxRates.reduce(
        (total, current) => total + current.amount,
        0
      );

      let finalSumUnderOneRate = [];
      for (let tr of taxRates) {
        if (
          finalSumUnderOneRate.filter((r) => tr.rate && r.rate == tr.rate)
            .length
        ) {
          finalSumUnderOneRate.filter(
            (r) => r.rate == tr.rate
          )[0].amountCollected += tr.amountCollected;
        } else if (tr.rate) {
          finalSumUnderOneRate.push(tr);
        }
      }

      let taxPaid = finalSumUnderOneRate.reduce(
        (total, current) => total + current.amountCollected,
        0
      );

      let totalTaxPaid = taxPaid || 0;

      setTotal(totalAmount);
      setSubTotal(totalAmount - totalTaxPaid);
      setTaxRates(finalSumUnderOneRate);
    }
  }, [invoice]);

  useEffect(() => {
    if (type === "Receipt") {
      setStatus("Paid");
    } else {
      setStatus("Unpaid");
    }
  }, [type]);

  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (invoiceData && invoiceData.items && invoiceData.items.length) {
      let items = invoiceData.items;
      let taxRates = [];
      for (let item of items) {
        let newTaxRate = {};
        if (item.gst) {
          newTaxRate["rate"] = item.gst;
          newTaxRate["amountCollected"] =
            (item.unitPrice *
              item.quantity *
              (1 - item.discount / 100) *
              item.gst) /
            100;
        }
        newTaxRate["amount"] =
          item.unitPrice *
          item.quantity *
          (1 - item.discount / 100) *
          (1 + item.gst / 100);
        taxRates.push(newTaxRate);
      }

      let totalAmount = taxRates.reduce(
        (total, current) => total + current.amount,
        0
      );

      let finalSumUnderOneRate = [];
      for (let tr of taxRates) {
        if (
          finalSumUnderOneRate.filter((r) => tr.rate && r.rate == tr.rate)
            .length
        ) {
          finalSumUnderOneRate.filter(
            (r) => r.rate == tr.rate
          )[0].amountCollected += tr.amountCollected;
        } else if (tr.rate) {
          finalSumUnderOneRate.push(tr);
        }
      }

      let taxPaid = finalSumUnderOneRate.reduce(
        (total, current) => total + current.amountCollected,
        0
      );

      let totalTaxPaid = taxPaid || 0;

      setTotal(totalAmount);
      setSubTotal(totalAmount - totalTaxPaid);
      setTaxRates(finalSumUnderOneRate);
    }
  }, [invoiceData]);

  const handleChange = (index, e) => {
    if (e.target.name === "itemName") {
      const values = [...invoiceData.items];

      let previousValue = values[index]?.itemName;

      values[index][e.target.name] = e.target.value;
      values[index]["quantity"] = 1;
      values[index]["unitPrice"] = allItems.filter(
        (i) => i._id == e.target.value
      )[0]?.price;
      values[index]["hsnCode"] = allItems.filter(
        (i) => i._id == e.target.value
      )[0]?.hsnCode;
      values[index]["gst"] = allItems.filter((i) => i._id == e.target.value)[0]
        ?.taxApplicable
        ? allItems.filter((i) => i._id == e.target.value)[0]?.taxRate
        : 0;

      setInvoiceData({ ...invoiceData, items: values });
      if (!previousValue) {
        handleAddField();
      }
    } else {
      const values = [...invoiceData.items];
      values[index][e.target.name] = e.target.value;
      setInvoiceData({ ...invoiceData, items: values });
    }
  };

  const handleAddField = (e) => {
    if (e) {
      e.preventDefault();
    }
    setInvoiceData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          itemName: "",
          unitPrice: "",
          quantity: "",
          discount: "",
          amount: "",
          gst: "",
          hsnCode: "",
        },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    const values = invoiceData.items;
    if (values.length == 1) return;
    values.splice(index, 1);
    setInvoiceData((prevState) => ({ ...prevState, values }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (invoice) {
      let payload = {
        ...invoiceData,
        items: invoiceData.items
          .filter((i) => i.unitPrice && i.quantity)
          .map((i) => ({
            itemId: i.itemName,
            name: allItems.filter((item) => item._id == i.itemName)[0].name,
            price: Number(i.unitPrice),
            hsnCode: i.hsnCode,
            quantity: Number(i.quantity),
            gst: Number(i.gst),
            discount: Number(i.discount),
            amount:
              i.unitPrice *
              i.quantity *
              (1 - i.discount / 100) *
              (1 + i.gst / 100),
          })),
        subTotal: subTotal,
        total: total,
        rates: taxRates,
        currency: "INR",
        dueDate: selectedDate,
        invoiceNumber: `${
          invoiceData.invoiceNumber < 100
            ? Number(invoiceData.invoiceNumber).toString().padStart(3, "0")
            : Number(invoiceData.invoiceNumber)
        }`,
        client,
        type: type,
        status: status,
        paymentRecords: [],
        creator: { ...user?.userProfile, _id: user?.userProfile?.userId[0] },
      };
      dispatch(updateInvoice(invoice._id, payload));

      history.push(`/invoice/${invoice._id}`);
    } else {
      let payload = {
        ...invoiceData,
        items: invoiceData.items
          .filter((i) => i.unitPrice && i.quantity)
          .map((i) => ({
            itemId: i.itemName,
            name: allItems.filter((item) => item._id == i.itemName)[0].name,
            price: Number(i.unitPrice),
            hsnCode: i.hsnCode,
            quantity: Number(i.quantity),
            gst: Number(i.gst),
            discount: Number(i.discount),
            amount:
              i.unitPrice *
              i.quantity *
              (1 - i.discount / 100) *
              (1 + i.gst / 100),
          })),
        subTotal: subTotal,
        total: total,
        rates: taxRates,
        currency: "INR",
        dueDate: selectedDate,
        invoiceNumber: `${
          invoiceData.invoiceNumber < 100
            ? Number(invoiceData.invoiceNumber).toString().padStart(3, "0")
            : Number(invoiceData.invoiceNumber)
        }`,
        client,
        type: type,
        status: status,
        paymentRecords: [],
        creator: { ...user?.userProfile, _id: user?.userProfile?.userId[0] },
      };

      // return console.log(payload, user.userProfile, "payload..............");
      dispatch(createInvoice(payload, history));
    }

    // setInvoiceData(initialState)
  };

  const classes = useStyles();
  const [open, setOpen] = useState("");

  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  if (!user) {
    history.push("/login");
  }

  const handleScroll = (e) => {
    e.preventDefault();
    e.target.blur();
  };

  return (
    <div style={{ paddingTop: "20px" }} className={styles.invoiceLayout}>
      <form onSubmit={handleSubmit} className="mu-form">
        <AddClient onClose={() => setOpen("")} open={open === "customer"} />
        <AddItem onClose={() => setOpen("")} open={open === "item"} />

        {/* Invoice Headerr */}
        <Container className={classes.headerContainer}>
          <Grid container justifyContent="space-between">
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
              alignItems="center"
              xs={12}
              sm={6}
            >
              <Typography
                style={{
                  lineSpacing: 1,
                  fontSize: 35,
                  fontWeight: 700,
                }}
              >
                {user?.userProfile?.businessName}
              </Typography>
              {/* <Avatar alt="Logo" variant='square' src="" className={classes.large} /> */}
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
              item
              xs={12}
              sm={6}
            >
              <InvoiceType type={type} setType={setType} />
              Invoice #:
              <div
                style={{
                  marginTop: "15px",
                  width: "100px",
                  padding: "8px",
                  display: "inline-block",
                  backgroundColor: "#f4f4f4",
                  outline: "0px solid transparent",
                  borderRadius: "5px",
                  marginLeft: "5px",
                }}
                contenteditable="true"
                onInput={(e) =>
                  setInvoiceData({
                    ...invoiceData,
                    invoiceNumber: e.currentTarget.textContent,
                  })
                }
              >
                <span
                  style={{ width: "40px", color: "black", padding: "15px" }}
                  contenteditable="false"
                >
                  {" "}
                  {invoiceData.invoiceNumber}
                </span>
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>

        <Divider />

        {/* / party info */}
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px" }}
          >
            <Grid item style={{ width: "50%" }}>
              <Container>
                <Typography
                  variant="overline"
                  style={{ color: "gray", paddingRight: "3px" }}
                  gutterBottom
                >
                  From
                </Typography>
                {user && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      {user?.userProfile?.businessName}
                    </Typography>
                    <Typography variant="body2">
                      {user?.userProfile?.email}
                    </Typography>
                    <Typography variant="body2">
                      {user?.userProfile?.phoneNumber}
                    </Typography>
                  </>
                )}
              </Container>
              <Container>
                <Typography
                  variant="overline"
                  style={{
                    color: "gray",
                    paddingRight: "3px",
                    marginTop: "20px",
                  }}
                  gutterBottom
                >
                  Bill to
                </Typography>
                {client && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      {client.name}
                    </Typography>
                    <Typography variant="body2">{client.email}</Typography>
                    <Typography variant="body2">{client.phone}</Typography>
                    <Typography variant="body2">{client.address}</Typography>
                    <Button
                      color="primary"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setClient(null)}
                      variant="outlined"
                    >
                      Change
                    </Button>
                  </>
                )}
                <div
                  style={client ? { display: "none" } : { display: "block" }}
                >
                  <Autocomplete
                    {...clientsProps}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={!invoice && true}
                        label="Select Customer"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    value={clients?.name}
                    onChange={(event, value) => setClient(value)}
                  />
                </div>
                {!client && (
                  <>
                    <Grid item style={{ paddingBottom: "10px" }}>
                      <Chip
                        avatar={<Avatar>+</Avatar>}
                        label="New Customer"
                        onClick={() => setOpen("customer")}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Container>
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: "right" }}>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Status
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: type === "Receipt" ? "green" : "red" }}
              >
                {type === "Receipt" ? "Paid" : "Unpaid"}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {moment().format("MMM Do YYYY")}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Due Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedDate
                  ? moment(selectedDate).format("MMM Do YYYY")
                  : "27th Sep 2024"}
              </Typography>
              <Typography variant="overline" gutterBottom>
                Amount
              </Typography>
              <Typography variant="h6" gutterBottom>
                INR {toCommas(total.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        {/* Items */}
        <div>
          <TableContainer component={Paper} className="tb-container">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>HSN/SAC</TableCell>

                  <TableCell>Qty</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>GST(%)</TableCell>

                  <TableCell>Disc(%)</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.items.map((itemField, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row" style={{ width: "20%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "40px",
                          display: "flex",
                          gridColumnGap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="itemName"
                          placeholder="Item name or description"
                          value={itemField.itemName}
                          onChange={(e) => handleChange(index, e)}
                          style={{
                            flex: 1,
                            height: "40px",
                          }}
                          autoWidth
                          variant="outlined"
                        >
                          {allItems.map((i) => (
                            <MenuItem value={i._id}>{i.name}</MenuItem>
                          ))}
                        </Select>
                        {allItems.filter((i) => i._id == itemField.itemName)[0]
                          ?.stock -
                          itemField.quantity <=
                        allItems.filter((i) => i._id == itemField.itemName)[0]
                          ?.lowStock ? (
                          <Typography
                            style={{
                              color: "red",
                              padding: "4px 6px",
                              backgroundColor: "pink",
                              borderRadius: "5px",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            LOW
                          </Typography>
                        ) : null}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <InputBase
                        type="text"
                        disabled
                        name="hsnCode"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.hsnCode}
                        style={{ textAlign: "center" }}
                        placeholder="HSN/SAC code"
                      />{" "}
                    </TableCell>
                    <TableCell ref={inputRef} align="center">
                      <InputBase
                        type="number"
                        name="quantity"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.quantity}
                        style={{ textAlign: "center" }}
                        onScroll={handleScroll}
                        placeholder="0"
                        onWheel={handleScroll}
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        disabled={true}
                        name="unitPrice"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.unitPrice}
                        onScroll={handleScroll}
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        disabled={true}
                        name="gst"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.gst}
                        placeholder="0"
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1, textAlign: "center" }}
                        type="number"
                        name="discount"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.discount}
                        placeholder="0"
                        onScroll={handleScroll}
                        onWheel={handleScroll}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="amount"
                        onChange={(e) => handleChange(index, e)}
                        value={(
                          (itemField.quantity * itemField.unitPrice -
                            (itemField.quantity *
                              itemField.unitPrice *
                              itemField.discount) /
                              100) *
                          (1 + itemField.gst / 100)
                        ).toFixed(2)}
                        disabled
                      />{" "}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        disabled={invoiceData.items.length < 1}
                        color="error"
                        onClick={() => handleRemoveField(index)}
                      >
                        <DeleteOutlineRoundedIcon
                          color="error"
                          fontSize="small"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.addButton}>
            {/* <button onClick={handleAddField}>+</button> */}
            <Chip
              avatar={<Avatar>+</Avatar>}
              label="Item"
              onClick={handleAddField}
              variant="outlined"
              style={{ marginRight: "10px" }}
            />
            <Chip
              avatar={<Avatar>+</Avatar>}
              label="New Item"
              onClick={() => setOpen("item")}
              variant="outlined"
            />
          </div>
        </div>

        <div className={styles.invoiceSummary}>
          <div className={styles.summary}>Invoice Summary</div>
          <div className={styles.summaryItem}>
            <p>Sub total:</p>
            <h4>{subTotal.toFixed(2)}</h4>
          </div>
          {taxRates?.map((r, i) => {
            return (
              <div key={i} className={styles.summaryItem}>
                <p>GST({r.rate}%):</p>
                <h4>{r.amountCollected?.toFixed(2)}</h4>
              </div>
            );
          })}
          <div className={styles.summaryItem}>
            <p>Total</p>
            <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>
              INR {toCommas(total.toFixed(2))}
            </h4>
          </div>
        </div>

        <div className={styles.toolBar}>
          <Container>
            <Grid container>
              <Grid item style={{ marginRight: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Due date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={styles.note}>
          <h4>Note/Payment Info</h4>
          <textarea
            style={{ border: "solid 1px #d6d6d6", padding: "10px" }}
            placeholder="Provide additional details or terms of service"
            onChange={(e) =>
              setInvoiceData({ ...invoiceData, notes: e.target.value })
            }
            value={invoiceData.notes}
          />
        </div>

        {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            type="submit"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save and Continue
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default Invoice;
