/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Avatar, Button, Paper, Grid, Container } from "@material-ui/core";
import Uploader from "./Uploader";
import { getProfilesByUser } from "../../../actions/profile";
import useStyles from "./styles";
import Input from "./Input";
import ProfileDetail from "./Profile";
import { updateProfile } from "../../../api";

const Settings = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    contactAddress: "",
    logo: "",
    paymentDetails: "",
    gstNo: "",
  };

  const [form, setForm] = useState(initialState);
  const [selectedFile, setSelectedFile] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { profiles } = useSelector((state) => state.profiles);

  const [switchEdit, setSwitchEdit] = useState(0);

  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => {
    if (switchEdit === 1) {
      setForm(profiles);
    }
  }, [switchEdit]);

  useEffect(() => {
    dispatch(
      getProfilesByUser({ search: user?.result?._id || user?.result?.googleId })
    );
  }, [location, switchEdit]);

  localStorage.setItem("profileDetail", JSON.stringify({ ...profiles }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      Object.keys(form).forEach((k) => formData.append(k, form[k]));
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      const { data } = await updateProfile(profiles?._id, formData);

      let initialProfile = JSON.parse(localStorage.getItem("profile"));
      if (initialProfile) {
        let newProfille = { ...initialProfile, userProfile: data };
        localStorage.setItem("profile", JSON.stringify(newProfille));
      }
      openSnackbar("Profile updated successfully!");
    } catch (error) {
      console.log(error);
    }
    // await dispatch(updateProfile(profiles?._id, form, openSnackbar));
    setSwitchEdit(0);
  };

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <div>
      {switchEdit === 0 && (
        <Container component="main" maxWidth="sm">
          <Paper className={classes.paper} elevation={0}>
            <ProfileDetail profiles={profiles} />
            <Button
              variant="outlined"
              style={{ margin: "30px", padding: "15px 30px" }}
              onClick={() => setSwitchEdit(1)}
            >
              Edit Profile
            </Button>
          </Paper>
        </Container>
      )}

      {switchEdit === 1 && (
        <Container component="main" maxWidth="sm">
          <Paper className={classes.paper} elevation={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: "solid 1px #dddddd",
                paddingBottom: "20px",
              }}
            >
              <Avatar
                style={{ width: "100px", height: "100px" }}
                src={
                  selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : profiles?.logo
                }
                alt="loading..."
                className={classes.avatar}
              />
            </div>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                container
                spacing={2}
              >
                {/* <Uploader form={form} setForm={setForm} />
                 */}
                <Button
                  style={{
                    marginBottom: 10,
                    width: "96%",
                    alignSelf: "center",
                  }}
                  variant="outlined"
                  size="large"
                  fullWidth
                  component="label"
                >
                  <input
                    hidden
                    accept=".jpg, .jpeg, .png"
                    type="file"
                    name="logo"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                  Upload Logo
                </Button>
                <Input
                  required={true}
                  name="email"
                  label="Email Address"
                  handleChange={handleChange}
                  type="email"
                  value={form?.email}
                />
                <Input
                  required={true}
                  name="phoneNumber"
                  label="Phone Number"
                  handleChange={handleChange}
                  type="text"
                  value={form?.phoneNumber}
                />
                <Input
                  required={true}
                  name="businessName"
                  label="Business Name"
                  handleChange={handleChange}
                  type="text"
                  value={form?.businessName}
                />
                <Input
                  name="gstNo"
                  label="GST Number"
                  handleChange={handleChange}
                  type="text"
                  value={form?.gstNo}
                />

                <Input
                  required={true}
                  name="contactAddress"
                  label="Contact Address"
                  handleChange={handleChange}
                  type="text"
                  value={form?.contactAddress}
                />
                <Input
                  name="paymentDetails"
                  label="Payment Details/Notes"
                  handleChange={handleChange}
                  type="text"
                  multiline
                  rows="4"
                  value={form?.paymentDetails}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update Settings
              </Button>
              <Grid container justifyContent="flex-end"></Grid>
            </form>
          </Paper>
        </Container>
      )}
    </div>
  );
};

export default Settings;
