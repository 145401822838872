import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import AddClient from "../Invoice/AddClient";
import AddItem from "../Items/AddItem";
// icons
import AddIcon from "@material-ui/icons/Add";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import CreateIcon from "@material-ui/icons/Create";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { BsBoxSeam } from "react-icons/bs";

const name = ["item", "customer", "invoice"];

const FabButton = () => {
  const location = useLocation();
  const mainButtonStyles = { backgroundColor: "#1976D2" };
  const [open, setOpen] = useState("");
  // if(location.pathname === '/invoice') return null

  return (
    <div>
      {/* ============ Add modals ==== */}
      <AddClient onClose={() => setOpen("")} open={open === "customer"} />
      <AddItem onClose={() => setOpen("")} open={open === "item"} />
      {/* ============== || ==============*/}

      <Fab
        mainButtonStyles={mainButtonStyles}
        icon={<AddIcon />}
        // onClick={() => setOpen("item")}
        alwaysShowTitle={true}
      >
        <Action text="Add Item" onClick={() => setOpen("item")}>
          <BsBoxSeam />
        </Action>

        {location.pathname !== "/invoice" && (
          <Action
            text="New Invoice"
            onClick={() => (window.location.href = "/invoice")}
          >
            <CreateIcon />
          </Action>
        )}

        <Action text="New Customer" onClick={() => setOpen("customer")}>
          <PersonAddIcon />
        </Action>
      </Fab>
    </div>
  );
};

export default FabButton;
