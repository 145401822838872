import React, { useState, useEffect, useRef } from "react";
// import "../../../node_modules/react-progress-button/react-progress-button.css"
import { useSnackbar } from "react-simple-snackbar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../initialState";
import { getInvoice } from "../../actions/invoiceActions";
import { toCommas } from "../../utils/utils";
import styles from "./InvoiceDetails.module.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Spinner from "../Spinner/Spinner";
import { useReactToPrint } from "react-to-print";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";

import ProgressButton from "react-progress-button";
import axios from "axios";
import { saveAs } from "file-saver";
import Modal from "../Payments/Modal";
import PaymentHistory from "./PaymentHistory";
import { downloadInvoicePfd, getItemList } from "../../api";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";

const InvoiceDetails = () => {
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState("INR");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [type, setType] = React.useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  const { id } = useParams();
  const { invoice } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendStatus, setSendStatus] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));

  const [allItems, setAllItems] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [downloadingInvoice, setDownloadingInvoice] = useState(false);

  useEffect(() => {
    document.title = `Invoie-${
      invoiceData?.invoiceNumber
    }-${new Date().toLocaleDateString()}`;

    return () =>
      (document.title =
        "Webspruce- Invoicing App for Freelancers and Small Businesses");
  }, [invoiceData]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getItems = async () => {
    try {
      const { data } = await getItemList();
      setAllItems(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(1),
      backgroundColor: "#f2f2f2",
      borderRadius: "10px 10px 0px 0px",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    dispatch(getInvoice(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (invoice) {
      setClient(invoice.client);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      let data = {
        ...invoice,
        items: [
          ...invoice.items.map((i) => ({
            ...i,
            itemName: i.itemId,
            unitPrice: i.price,
          })),
        ],
      };
      setInvoiceData(data);

      let items = data.items;
      let taxRates = [];
      for (let item of items) {
        let newTaxRate = {};
        if (item.gst) {
          newTaxRate["rate"] = item.gst;
          newTaxRate["amountCollected"] =
            (item.unitPrice *
              item.quantity *
              (1 - item.discount / 100) *
              item.gst) /
            100;
        }
        newTaxRate["amount"] =
          item.unitPrice *
          item.quantity *
          (1 - item.discount / 100) *
          (1 + item.gst / 100);
        taxRates.push(newTaxRate);
      }

      let totalAmount = taxRates.reduce(
        (total, current) => total + current.amount,
        0
      );

      let finalSumUnderOneRate = [];
      for (let tr of taxRates) {
        if (
          finalSumUnderOneRate.filter((r) => tr.rate && r.rate == tr.rate)
            .length
        ) {
          finalSumUnderOneRate.filter(
            (r) => r.rate == tr.rate
          )[0].amountCollected += tr.amountCollected;
        } else if (tr.rate) {
          finalSumUnderOneRate.push(tr);
        }
      }

      let taxPaid = finalSumUnderOneRate.reduce(
        (total, current) => total + current.amountCollected,
        0
      );

      let totalTaxPaid = taxPaid || 0;

      setTotal(totalAmount);
      setSubTotal(totalAmount - totalTaxPaid);
      setTaxRates(finalSumUnderOneRate);
    }
  }, [invoice]);

  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid);
  }

  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };

  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    axios
      .post(`${process.env.REACT_APP_API}/create-pdf`, {
        name: invoice.client.name,
        address: invoice.client.address,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        items: invoice.items,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        company: company,
      })
      .then(() =>
        axios.get(`${process.env.REACT_APP_API}/fetch-pdf`, {
          responseType: "blob",
        })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, "invoice.pdf");
      })
      .then(() => setDownloadStatus("success"));
  };

  //SEND PDF INVOICE VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault();
    setSendStatus("loading");
    axios
      .post(`${process.env.REACT_APP_API}/send-pdf`, {
        name: invoice.client.name,
        address: invoice.client.address,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        items: invoice.items,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        link: `${process.env.REACT_APP_URL}/invoice/${invoice._id}`,
        company: company,
      })
      // .then(() => console.log("invoice sent successfully"))
      .then(() => setSendStatus("success"))
      .catch((error) => {
        console.log(error);
        setSendStatus("error");
      });
  };

  const downloadPdf = async (id) => {
    setDownloadingInvoice(true);
    try {
      const { data } = await downloadInvoicePfd(id);
      setDownloadingInvoice(false);

      const downloadUri = URL.createObjectURL(data);
      window.open(downloadUri, "__blank");
    } catch (error) {}
    setDownloadingInvoice(false);
  };

  const iconSize = {
    height: "18px",
    width: "18px",
    marginRight: "10px",
    color: "gray",
  };
  const [open, setOpen] = useState(false);

  function checkStatus() {
    return totalAmountReceived >= total
      ? "green"
      : status === "Partial"
      ? "#1976d2"
      : status === "Paid"
      ? "green"
      : status === "Unpaid"
      ? "red"
      : "red";
  }

  if (!invoice) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        paddingLeft: { xs: 0, sm: 100 },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <button
          disabled={downloadingInvoice}
          className={styles.btn}
          onClick={() => history.push("/invoices")}
        >
          <ListRoundedIcon style={iconSize} />
          All Invoices
        </button>
        <button
          disabled={downloadingInvoice}
          className={styles.btn}
          onClick={handlePrint}
        >
          {downloadingInvoice ? (
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              Downloading.......
            </Typography>
          ) : (
            <>
              <LocalPrintshopRoundedIcon style={iconSize} />
              Print {invoiceData?.type || "Invoice"}
            </>
          )}
        </button>

        {invoiceData.status == "Paid" ? null : (
          <button
            className={styles.btn}
            onClick={() => editInvoice(invoiceData._id)}
          >
            <BorderColorIcon style={iconSize} />
            Edit Invoice
          </button>
        )}

        {invoiceData.status === "Paid" ? null : (
          <button
            // disabled={status === 'Paid' ? true : false}
            className={styles.btn}
            onClick={() => setOpen((prev) => !prev)}
          >
            <CurrencyRupeeRoundedIcon style={iconSize} />
            Collect Payment
          </button>
        )}
      </div>

      {invoice?.paymentRecords.length !== 0 && (
        <PaymentHistory paymentRecords={invoiceData?.paymentRecords} />
      )}

      <Modal open={open} setOpen={setOpen} invoice={invoice} />
      <div
        ref={componentRef}
        style={{ scale: "0.9" }}
        className={styles.invoiceLayout}
      >
        <Container className={classes.headerContainer}>
          <Grid
            container
            justifyContent="space-between"
            style={{ padding: "30px 0px" }}
          >
            {!invoice?.creator._id == user?.userProfile?._id ? (
              <Grid item></Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                onClick={() => history.push("/settings")}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  // backgroundColor: "red",
                }}
              >
                {user?.userProfile?.logo ? (
                  <img
                    src={user?.userProfile?.logo}
                    alt="Logo"
                    className={styles.logo}
                    style={{ objectFit: "contain" }}
                  />
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <h2 style={{ textAlign: "left" }}>
                    {invoiceData?.creator?.businessName}
                  </h2>
                  <p style={{ textAlign: "left", marginTop: "5px" }}>
                    {invoiceData?.creator?.email}
                  </p>
                  <p style={{ textAlign: "left" }}>
                    {invoiceData?.creator?.phoneNumber}
                  </p>
                  <p style={{ textAlign: "left", marginBottom: "5px" }}>
                    {invoiceData?.creator?.contactAddress}
                  </p>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {invoiceData?.creator?.gstNo}
                  </p>
                </div>
              </Grid>
            )}
            <Grid
              xs={12}
              sm={6}
              item
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // backgroundColor: "red",
                paddingRight: "20px",
                alignItems: "flex-end",
              }}
            >
              <Typography
                style={{
                  lineSpacing: 1,
                  fontSize: 45,
                  fontWeight: 700,
                  color: "gray",
                }}
              >
                {Number(total - totalAmountReceived) <= 0 ? "Receipt" : type}
              </Typography>
              <Typography variant="overline" style={{ color: "gray" }}>
                No:{" "}
              </Typography>
              <Typography variant="body2">
                {invoiceData?.invoiceNumber}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px" }}
          >
            <Grid item>
              {invoice?.creator?._id === user?.userProfile?._id && (
                <Container style={{ marginBottom: "20px" }}>
                  <Typography
                    variant="overline"
                    style={{ color: "gray" }}
                    gutterBottom
                  >
                    From
                  </Typography>
                  <Typography variant="subtitle2">
                    {invoice?.businessDetails?.data?.data?.businessName}
                  </Typography>
                  <Typography variant="body2">
                    {invoice?.businessDetails?.data?.data?.email}
                  </Typography>
                  <Typography variant="body2">
                    {invoice?.businessDetails?.data?.data?.phoneNumber}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {invoice?.businessDetails?.data?.data?.address}
                  </Typography>
                </Container>
              )}
              <Container>
                <Typography
                  variant="overline"
                  style={{ color: "gray", paddingRight: "3px" }}
                  gutterBottom
                >
                  Bill to
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  {client.name}
                </Typography>
                <Typography variant="body2">{client?.email}</Typography>
                <Typography variant="body2">{client?.phone}</Typography>
                <Typography variant="body2">{client?.address}</Typography>
              </Container>
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: "right" }}>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Status
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: checkStatus() }}
              >
                {totalAmountReceived >= total ? "Paid" : status}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {moment().format("MMM Do YYYY")}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Due Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedDate
                  ? moment(selectedDate).format("MMM Do YYYY")
                  : "27th Sep 2021"}
              </Typography>
              <Typography variant="overline" gutterBottom>
                Amount
              </Typography>
              <Typography variant="h6" gutterBottom>
                {currency} {toCommas(total?.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>HSN/SAC</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>GST(%)</TableCell>
                    <TableCell>Disc(%)</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.items?.map((itemField, index) => (
                    <TableRow key={index}>
                      <TableCell scope="row" style={{ width: "18%" }}>
                        {" "}
                        <InputBase
                          style={{ width: "100%" }}
                          outline="none"
                          sx={{ ml: 1, flex: 1 }}
                          type="text"
                          name="itemName"
                          value={
                            allItems.find((v) => v._id == itemField.itemName)
                              ?.name
                          }
                          placeholder="Item name or description"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell scope="row" style={{ width: "14%" }}>
                        {" "}
                        <InputBase
                          style={{ width: "100%" }}
                          outline="none"
                          sx={{ ml: 1, flex: 1 }}
                          type="text"
                          name="itemName"
                          value={itemField.hsnCode}
                          placeholder="HSN/SAC code"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="center" style={{ width: "14%" }}>
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="quantity"
                          value={itemField?.quantity}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="center" style={{ width: "14%" }}>
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="unitPrice"
                          value={itemField?.unitPrice}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="center" style={{ width: "14%" }}>
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="gst"
                          value={itemField?.gst}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="center" style={{ width: "14%" }}>
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="discount"
                          value={itemField?.discount}
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="center" style={{ width: "14%" }}>
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="amount"
                          value={(
                            (itemField.quantity * itemField.unitPrice -
                              (itemField.quantity *
                                itemField.unitPrice *
                                itemField.discount) /
                                100) *
                            (1 + itemField.gst / 100)
                          ).toFixed(2)}
                          readOnly
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addButton}></div>
          </div>

          <div className={styles.invoiceSummary}>
            <div className={styles.summary}>Invoice Summary</div>
            <div className={styles.summaryItem}>
              <p>Subtotal:</p>
              <h4>{subTotal?.toFixed(2)}</h4>
            </div>

            {taxRates?.map((r, i) => {
              return (
                <div key={i} className={styles.summaryItem}>
                  <p>GST({r.rate}%):</p>
                  <h4>{r.amountCollected?.toFixed(2)}</h4>
                </div>
              );
            })}

            <div className={styles.summaryItem}>
              <p>Total</p>
              <h4>
                {currency} {toCommas(total?.toFixed(2))}
              </h4>
            </div>
            <div className={styles.summaryItem}>
              <p>Paid</p>
              <h4>
                {currency}{" "}
                {invoiceData.type === "Receipt"
                  ? invoiceData.total?.toFixed(2)
                  : toCommas(totalAmountReceived?.toFixed(2))}
              </h4>
            </div>

            <div className={styles.summaryItem}>
              <p>Balance</p>
              <h4
                style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}
              >
                {currency}{" "}
                {invoiceData.type === "Receipt"
                  ? 0
                  : toCommas((total - totalAmountReceived).toFixed(2))}
              </h4>
            </div>
          </div>

          <div className={styles.note}>
            <h4 style={{ marginLeft: "-10px" }}>Note/Payment Info</h4>
            <p style={{ fontSize: "14px" }}>{invoiceData.notes}</p>
          </div>

          <div style={{ width: "100%" }}>
            <p style={{ fontSize: "14px", textAlign: "center", width: "100%" }}>
              This is an automatically generated invoice/receipt from the system
              and does not require a signature
            </p>
          </div>

          {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        </form>
      </div>
    </div>
  );
};

export default InvoiceDetails;
