import { Box, Typography } from "@material-ui/core";
import React from "react";

export default function PoweredBy() {
  return (
    <Box
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        alignSelf: "center",
        width: "100%",
        justifyContent: "center",
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
        @2024 BillMadi. Proudly Powered By{" "}
        <Typography
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#d65222",
          }}
          onClick={() => window.open("https://webspruce.com", "_blank")}
          component={"span"}
        >
          Webspruce
        </Typography>
      </Typography>
    </Box>
  );
}
