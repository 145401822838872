import React, { useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Switch,
  Typography,
  styled,
  withStyles,
} from "@material-ui/core";
import { createItem, updateItem } from "../../api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UpdateCountContext from "../../context/UpdateCountContext";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});
const inputStyle = {
  display: "block",
  padding: "1.2rem 0.75rem",
  width: "100%",
  fontSize: "0.8rem",
  lineHeight: 1.25,
  color: "#55595c",
  backgroundColor: "#fff",
  backgroundImage: "none",
  backgroundClip: "padding-box",
  borderTop: "0",
  borderRight: "0",
  marginBottom: "10px",
  borderBottom: "1px solid #eee",
  borderLeft: "0",
  borderRadius: "3px",
  transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)",
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MainBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#1876d31f",
  padding: "10px 20px",
  borderRadius: "5px",
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const defaultItem = {
  name: "",
  price: "",
  descriptions: "",
  stock: 0,
  lowStock: 0,
  hsnCode: "",
  taxRate: "",
};

export default function AddItem({
  onClose,
  open,
  itemToEdit,
  onItemUpdate = () => {},
}) {
  const history = useHistory();
  const { count, setCount } = useContext(UpdateCountContext);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [checked, setChecked] = React.useState(
    itemToEdit ? itemToEdit.taxApplicable : false
  );
  const [item, setItem] = React.useState(
    itemToEdit
      ? {
          name: itemToEdit.name,
          price: itemToEdit.price,
          descriptions: itemToEdit.description,
          stock: itemToEdit.stock,
          lowStock: itemToEdit.lowStock,
          hsnCode: itemToEdit.hsnCode,
          taxRate: itemToEdit.taxRate,
        }
      : defaultItem
  );

  const handleClose = () => {
    onClose();
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleSubmit = async () => {
    const { name, price, descriptions, stock, lowStock, hsnCode, taxRate } =
      item;
    if (!name) return alert("Please enter item name!");
    if (!price) return alert("Please enter price");
    if (!stock) return alert("Please enter stock");
    if (checked && !taxRate) return alert("Please select applicable GST rate");

    let payload = {
      name,
      price: Number(price),
      description: descriptions,
      stock: Number(stock),
      lowStock: Number(lowStock),
      taxApplicable: checked,
      taxRate: Number(taxRate),
      hsnCode,
    };

    try {
      const { data } = itemToEdit
        ? await updateItem(itemToEdit._id, payload)
        : await createItem(payload);
      if (itemToEdit) {
        onItemUpdate();
        handleClose();
        openSnackbar("Item updated successfully!");
      } else {
        openSnackbar("Item added successfully!");

        handleClose();
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            paddingLeft: "20px",
            color: "white",
            backgroundColor: "#1976D2",
          }}
        >
          {itemToEdit ? "Edit Item" : "Add Item"}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 30 }} component="form">
          <div className="customInputs">
            <label
              aria-required="true"
              style={{ fontSize: "14px", padding: "10px", marginTop: "20px" }}
            >
              Item Name
            </label>
            <input
              required={true}
              placeholder="Enter Name"
              style={inputStyle}
              name="name"
              type="text"
              onChange={(e) => setItem({ ...item, name: e.target.value })}
              value={item.name}
            />
            <label
              style={{ fontSize: "14px", padding: "10px", marginTop: "20px" }}
            >
              Descriptions
            </label>
            <input
              placeholder="Enter Descriptions"
              style={inputStyle}
              name="descriptions"
              type="text"
              onChange={(e) =>
                setItem({ ...item, descriptions: e.target.value })
              }
              value={item.descriptions}
            />
            <label
              aria-required="true"
              style={{ fontSize: "14px", padding: "10px", marginTop: "20px" }}
            >
              Price
            </label>
            <input
              required={true}
              placeholder="Enter Price"
              style={inputStyle}
              name="price"
              type="text"
              onChange={(e) => setItem({ ...item, price: e.target.value })}
              value={item.price}
            />
            <label
              aria-required="true"
              style={{ fontSize: "14px", padding: "10px" }}
            >
              Stock
            </label>
            <input
              style={inputStyle}
              required={true}
              name="stock"
              type="number"
              onChange={(e) => setItem({ ...item, stock: e.target.value })}
              value={item.stock}
            />
            <label style={{ fontSize: "14px", padding: "10px" }}>
              Low Stock
            </label>
            <input
              style={inputStyle}
              name="lowStock"
              type="number"
              onChange={(e) => setItem({ ...item, lowStock: e.target.value })}
              value={item.lowStock}
            />
            <MainBox
              sx={{
                my: 3,
              }}
            >
              <Typography>Tax included</Typography>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={checked}
                    onChange={toggleChecked}
                    name="checkedB"
                  />
                }
              />
            </MainBox>
            {checked && (
              <>
                <FormControl
                  required={checked}
                  variant="filled"
                  style={{ width: "100%" }}
                >
                  <InputLabel htmlFor="filled-age-native-simple">
                    Select GST%
                  </InputLabel>
                  <Select
                    native
                    value={item.taxRate}
                    onChange={(e) =>
                      setItem({ ...item, taxRate: e.target.value })
                    }
                  >
                    <option value={0}>GST 0% (NO GST )</option>
                    <option value={0.1}>
                      GST 0.1% (0.05% CSGT + 0.05% SGST/UT GST ; 0.1% IGST )
                    </option>
                    <option value={0.25}>
                      GST 0.25% (0.125% CSGT + 0.125% SGST/UT GST ; 0.25% IGST )
                    </option>
                    <option value={5}>
                      GST 5% (1.5% CSGT + 1.5% SGST/UT GST ; 3% IGST )
                    </option>
                    <option value={6}>
                      GST 6% (2.5% CSGT + 2.5% SGST/UT GST ; 5% IGST )
                    </option>
                    <option value={7.5}>
                      GST 7.5% (3.75% CSGT + 3.75% SGST/UT GST ; 7.5% IGST )
                    </option>
                    <option value={12}>
                      GST 12% (6% CSGT + 6% SGST/UT GST ; 12% IGST )
                    </option>
                    <option value={18}>
                      GST 18% (9% CSGT + 9% SGST/UT GST ; 18% IGST )
                    </option>
                    <option value={28}>
                      GST 28% (14% CSGT + 14% SGST/UT GST ; 28% IGST )
                    </option>
                  </Select>
                </FormControl>
                <input
                  placeholder="Enter HSN code"
                  style={inputStyle}
                  name="hsnCode"
                  type="text"
                  value={item.hsnCode}
                  onChange={(e) =>
                    setItem({ ...item, hsnCode: e.target.value })
                  }
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            autoFocus
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginBottom: "20px",
              alignSelf: "center",
            }}
          >
            {itemToEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
