import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  styled,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { deleteClient } from "../../actions/clientActions";
// icons
import { RiDeleteBin5Line } from "react-icons/ri";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const MuiDialogTitle = styled(DialogTitle)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export default function DeleteModal({
  open,
  text,
  id,
  setModalOpen,
  onDeleteClick = () => {},
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setModalOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <MuiDialogTitle id="responsive-dialog-title">
          <DeleteRoundedIcon fontSize="large" color="warning" />
          Are You Sure?
        </MuiDialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete this {text}?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
          <Button
            variant="outlined"
            color="inherit"
            autoFocus
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={(e) => {
              e.preventDefault();
              onDeleteClick(id);
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
