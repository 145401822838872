import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import Logo from "../../assets/logowithname.png";
import { Typography } from "@material-ui/core";
import WebspruceLog from "../../assets/websapruce.png";

const NavBar = () => {
  const location = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  if (!user) return null;

  return (
    <div>
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="logo">
            <a href="/dashboard" className="nav-link_db">
              <span className="link-text logo-text">
                <img
                  style={{
                    width: "150px",
                    height: "auto",
                  }}
                  src={Logo}
                  alt="webspruce-logo"
                />
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </a>
          </li>
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-pie-chart"
              >
                <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
              </svg>
              <span className="link-text">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <a href="/invoice" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              <span className="link-text">Create</span>
            </a>
          </li>
          <li className="nav-item" id="themeButton">
            <a href="/item" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-package"
              >
                <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
              <span className="link-text">Items</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/invoices" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-layers"
              >
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
              <span className="link-text">Invoices</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="/customers" className="nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-users"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span className="link-text">Customers</span>
            </a>
          </li>

          <li className="nav-item" id="themeButton">
            <a
              href="https://webspruce.com"
              target="_blank"
              // className="nav-link"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "85px",
                backgroundColor: "black",
                flexDirection: "column",
                textDecoration: "none",
                fontSize: "14px",
                color: "white",
                paddingTop: "20px",
                paddingBottom: "20px",
                rowGap: "10px",
              }}
            >
              <span style={{ fontSize: "14px" }}>
                Powered By{" "}
                {/* <Typography
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "#d65222",
                    fontSize: "14px",
                  }}
                  component={"span"}
                >
                  Webspruce
                </Typography> */}
              </span>
              <img
                src={WebspruceLog}
                style={{ height: "60px", width: "95%", objectFit: "contain" }}
              />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
